import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth, API, graphqlOperation } from 'aws-amplify';
// import { amplifyConfig } from '../config';
import amplifyConfig from '../aws-exports';
import { getStaffInfo } from '../graphql/queries';
Amplify.configure(amplifyConfig);

const initialState = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	group: null,
};

const handlers = {
	INITIALIZE: (state, action) => {
		const { isAuthenticated, user, group } = action.payload;

		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user,
			group,
		};
	},
	LOGIN: (state, action) => {
		const { user, group } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
			group,
		};
	},
	LOGOUT: (state) => ({
		...state,
		isAuthenticated: false,
		user: null,
		group: null,
	}),
	REGISTER: (state) => ({ ...state }),
	VERIFY_CODE: (state) => ({ ...state }),
	RESEND_CODE: (state) => ({ ...state }),
	PASSWORD_RECOVERY: (state) => ({ ...state }),
	PASSWORD_RESET: (state) => ({ ...state }),
};

const reducer = (state, action) =>
	handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext({
	...initialState,
	platform: 'Amplify',
	login: () => Promise.resolve(),
	logout: () => Promise.resolve(),
	register: () => Promise.resolve(),
	verifyCode: () => Promise.resolve(),
	resendCode: () => Promise.resolve(),
	passwordRecovery: () => Promise.resolve(),
	passwordReset: () => Promise.resolve(),
});

const getGroup = (user) => {
	try {
		const group =
			user.signInUserSession.accessToken.payload['cognito:groups'][0];
		return group;
	} catch (err) {
		return null;
	}
};

export const AuthProvider = (props) => {
	const { children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		const initialize = async () => {
			try {
				const user = await Auth.currentAuthenticatedUser();
				const group = getGroup(user);
				// Here you should extract the complete user profile to make it
				// available in your entire app.
				// The auth state only provides basic information.

				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticated: true,
						user,
						group,
					},
				});
			} catch (error) {
				dispatch({
					type: 'INITIALIZE',
					payload: {
						isAuthenticated: false,
						user: null,
						group: null,
					},
				});
			}
		};

		initialize();
	}, []);

	const login = async (email, password) => {
		const user = await Auth.signIn(email, password);
		const group = getGroup(user);

		if (user.challengeName) {
			console.error(
				`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`
			);
			return;
		}

		dispatch({
			type: 'LOGIN',
			payload: {
				user,
				group,
			},
		});
		return user;
	};
	const updateContextGroup = async (user) => {
		const group = getGroup(user);
		console.log('update context group');
		if (user.challengeName) {
			console.error(
				`Unable to login, because challenge "${user.challengeName}" is mandated and we did not handle this case.`
			);
			return;
		}

		dispatch({
			type: 'LOGIN',
			payload: {
				group,
				user,
			},
		});
	};

	const logout = async () => {
		await Auth.signOut();
		dispatch({
			type: 'LOGOUT',
		});
	};

	const register = async (email, password) => {
		await Auth.signUp({
			username: email,
			password,
			attributes: { email },
		});
		dispatch({
			type: 'REGISTER',
		});
	};

	const verifyCode = async (username, code) => {
		await Auth.confirmSignUp(username, code);
		dispatch({
			type: 'VERIFY_CODE',
		});
	};

	const resendCode = async (username) => {
		await Auth.resendSignUp(username);
		dispatch({
			type: 'RESEND_CODE',
		});
	};

	const passwordRecovery = async (username) => {
		await Auth.forgotPassword(username);
		dispatch({
			type: 'PASSWORD_RECOVERY',
		});
	};

	const passwordReset = async (username, code, newPassword) => {
		await Auth.forgotPasswordSubmit(username, code, newPassword);
		dispatch({
			type: 'PASSWORD_RESET',
		});
	};
	// DBからFullNameを取得
	const getFullName = async (userName) => {
		const input = {
			id: userName
		};
		const res_gq = await API.graphql(
			graphqlOperation(getStaffInfo, JSON.stringify(input))
		);
		const isDeleted = res_gq.data.getStaffInfo? res_gq.data.getStaffInfo.delete_flag: true;
		if (res_gq.data.getStaffInfo && !isDeleted) {
			return res_gq.data.getStaffInfo.fullname;
		} else {
			return '';
			// throw new Error('getFullName error');
		}
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				platform: 'Amplify',
				login,
				logout,
				register,
				verifyCode,
				resendCode,
				passwordRecovery,
				passwordReset,
				updateContextGroup,
				getFullName
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.node.isRequired,
};

export default AuthContext;
