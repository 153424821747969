import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Divider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NavSection from './NavSection';

const AppSidebar = (props) => {
	const { onMobileClose, openMobile, sections, path } = props;
	const location = useLocation();
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	return (
		<Drawer
			anchor="left"
			onClose={onMobileClose}
			open={!lgUp && openMobile}
			variant="temporary"
			PaperProps={{
				sx: {
					backgroundColor: 'background.default',
					width: 256,
				},
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Box
					sx={{
						display: {
							lg: 'none',
							xs: 'flex',
						},
						justifyContent: 'center',
						p: 2,
					}}
				>
					<RouterLink to={path ? path.iconPath : ''}>
						<Box>
							<img
								alt="Logo"
								src="/static/logo.jpeg"
								style={{ maxWidth: '100%', height: 'auto' }}
							/>
						</Box>
					</RouterLink>
				</Box>
				<Divider />
				<Box sx={{ p: 2 }}>
					{sections.map((section, index) => (
						<NavSection
							key={index}
							pathname={location.pathname}
							sx={{
								'& + &': {
									mt: 3,
								},
							}}
							{...section}
						/>
					))}
				</Box>
			</Box>
		</Drawer>
	);
};

AppSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default AppSidebar;
