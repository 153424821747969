import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import AppNavbar from './AppNavbar';
import AppSidebar from './AppSidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import UserIcon from '../icons/User';
import CogIcon from '../icons/Cog';
import ArrowRightIcon from '../icons/ArrowRight';

const AppLayoutRoot = experimentalStyled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	height: '100%',
	paddingTop: 64,
}));

const AppLayout = ({ children }) => {
	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const [path, setPath] = useState();
	const [menuText, setMenuText] = useState();

	useEffect(() => {
		Auth.currentAuthenticatedUser()
			.then((user) => {
				//TODO validate below statement and copy to other pages which need to be authorized before release
				// const group = user.signInUserSession.accessToken.payload['cognito:groups'];
				// if (group.indexOf('mentor')===-1) throw 'this user is not mentor';
			})
			.catch((err) => {
				console.log(err);
				navigate('/');
			});
	}, []);

	useEffect(() => {
		let iconPath = '';
		let userPath = '';
		let logoutPath = '';
		let resetPasswordPath = '';
		if (location && location.pathname) {
			if (location.pathname.includes('mentor')) {
				iconPath = '/mentor/meeting/list';
				userPath = '/mentor/profile';
				logoutPath = '/mentor/login';
				resetPasswordPath = '/mentor/passwordsetting/logged';
				setMenuText('プロフィール参照');
			} else {
				iconPath = '/meeting/list';
				userPath = '/profile/edit';
				logoutPath = '/login';
				resetPasswordPath = '/passwordsetting/logged';
				setMenuText('プロフィール編集');
			}
		}
		setPath({
			iconPath,
			userPath,
			logoutPath,
			resetPasswordPath,
		});
	}, []);

	const sections = [
		{
			items: [
				{
					title: menuText,
					path: path ? path.userPath : '',
					icon: <UserIcon fontSize="small" />,
				},
				{
					title: 'パスワード再設定',
					path: path ? path.resetPasswordPath : '',
					icon: <CogIcon fontSize="small" />,
				},
				{
					title: 'ログアウト',
					path: path ? path.logoutPath : '',
					icon: <ArrowRightIcon fontSize="small" />,
				},
			],
		},
	];

	return (
		<AppLayoutRoot>
			<AppNavbar
				onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
				path={path}
				menuText={menuText}
			/>
			<AppSidebar
				path={path}
				sections={sections}
				onMobileClose={() => setIsSidebarMobileOpen(false)}
				openMobile={isSidebarMobileOpen}
			/>
			{children || <Outlet />}
		</AppLayoutRoot>
	);
};

AppLayout.propTypes = {
	children: PropTypes.node,
};

export default AppLayout;
