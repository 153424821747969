import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Divider, IconButton, Toolbar, Box } from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import useAuth from '../hooks/useAuth';

const MainNavbar = (props) => {
	const { onSidebarMobileOpen } = props;
	const { group } = useAuth();

	const topUrl = () => {
		switch (group) {
			case 'student':
				return '/meeting/list';
			case 'mentor':
				return '/mentor/meeting/list';
			case 'staff':
				return '/admin/dashboard';
			default:
				return '/';
		}
	};

	console.log('group::', group);
	console.log('topUrl::', topUrl());

	return (
		<AppBar
			elevation={0}
			sx={{
				backgroundColor: 'background.paper',
				color: 'text.secondary',
			}}
		>
			<Toolbar sx={{ minHeight: 64 }}>
				<IconButton
					color="inherit"
					onClick={onSidebarMobileOpen}
					sx={{
						display: {
							md: 'none',
						},
					}}
				>
					<MenuIcon fontSize="small" />
				</IconButton>
				<RouterLink to={topUrl()}>
					<Box
						sx={{
							display: {
								md: 'block',
								xs: 'none',
							},
							width: 300,
						}}
					>
						<img
							alt="Logo"
							src="/static/logo.jpeg"
							style={{ maxWidth: '100%', height: 'auto' }}
						/>
					</Box>
				</RouterLink>
			</Toolbar>
			<Divider />
		</AppBar>
	);
};

MainNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
