import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
	AppBar,
	Box,
	Divider,
	IconButton,
	Link,
	Toolbar,
} from '@material-ui/core';
import MenuIcon from '../icons/Menu';
import AccountPopover from './AccountPopover';

const AppNavbar = (props) => {
	const { onSidebarMobileOpen, path, menuText } = props;

	return (
		<AppBar
			elevation={0}
			sx={{
				backgroundColor: 'background.paper',
				color: 'text.secondary',
			}}
		>
			<Toolbar sx={{ minHeight: 64 }}>
				<IconButton
					color="inherit"
					onClick={onSidebarMobileOpen}
					sx={{
						display: {
							md: 'none',
						},
					}}
				>
					<MenuIcon fontSize="small" />
				</IconButton>
				<RouterLink to={path ? path.iconPath : ''}>
					<Box
						sx={{
							display: {
								md: 'block',
								xs: 'none',
							},
							width: '300px',
						}}
					>
						<img
							alt="Logo"
							src="/static/logo.jpeg"
							style={{ maxWidth: '100%', height: 'auto', paddingTop: 5 }}
						/>
					</Box>
				</RouterLink>
				<Box sx={{ flexGrow: 1 }} />
				<Box
					sx={{
						alignItems: 'center',
						display: {
							md: 'flex',
							xs: 'none',
						},
					}}
				>
					<AccountPopover path={path} menuText={menuText} />
					<Divider
						orientation="vertical"
						sx={{
							height: 32,
							mx: 2,
						}}
					/>
					<Link
						color="textSecondary"
						component={RouterLink}
						to={path ? path.logoutPath : ''}
						underline="none"
						variant="body1"
					>
						ログアウト
					</Link>
				</Box>
			</Toolbar>
			<Divider />
		</AppBar>
	);
};

AppNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default AppNavbar;
