import { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ChartSquareBarIcon from '../icons/ChartSquareBar';
import ClipboardListIcon from '../icons/ClipboardList';
import UsersIcon from '../icons/Users';
import NavSection from './NavSection';
import Scrollbar from './Scrollbar';
import UserIcon from '../icons/User';
import ArrowRightIcon from '../icons/ArrowRight';
//amplify
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { getStaffInfo } from '../graphql/queries';
import config from '../aws-exports';
Amplify.configure(config);

const sections = [
	{
		title: '利用状況',
		items: [
			{
				title: 'ダッシュボード',
				path: '/admin/dashboard',
				icon: <ChartSquareBarIcon fontSize="small" />,
			},
		],
	},
	{
		title: 'ユーザー管理',
		items: [
			{
				title: '在学生一覧',
				path: '/admin/student/list',
				icon: <UsersIcon fontSize="small" />,
			},
			{
				title: '社会人メンター一覧',
				path: '/admin/mentor/list',
				icon: <UsersIcon fontSize="small" />,
			},
			{
				title: '未承認メンター一覧',
				path: '/admin/unapproved/list',
				icon: <UsersIcon fontSize="small" />,
			},
		],
	},
	{
		title: '面談管理',
		items: [
			{
				title: '面談一覧',
				path: '/admin/meeting/list',
				icon: <ClipboardListIcon fontSize="small" />,
			},
			{
				title: '事務局対応一覧',
				path: '/admin/mailsupport/list',
				icon: <ClipboardListIcon fontSize="small" />,
			},
		],
	},
];

const DashboardSidebar = (props) => {
	const { onMobileClose, openMobile } = props;
	const location = useLocation();
	const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	const navigate = useNavigate();
	const [fullName, setFullName] = useState('');

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	useEffect(() => {
		//deny unauthorized user access
		Auth.currentAuthenticatedUser()
			.then((user) => {
				getFullName(user.username);
			})
			.catch((err) => {
				navigate('/admin/login');
			});
	}, []);

	const getFullName = async (userName) => {
		const input = {
			id: userName,
		};
		const res_gq = await API.graphql(
			graphqlOperation(getStaffInfo, JSON.stringify(input))
		);
		if (res_gq.data.getStaffInfo) {
			setFullName(res_gq.data.getStaffInfo.fullname);
		} else {
			throw new Error('getFullName error');
		}
	};

	const logoutSection = [
		{
			items: [
				{
					title: fullName,
					icon: <UserIcon fontSize="small" />,
				},
				{
					title: 'ログアウト',
					path: '/admin/login',
					icon: <ArrowRightIcon fontSize="small" />,
				},
			],
		},
	];

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Box
					sx={{
						display: {
							lg: 'none',
							xs: 'flex',
						},
						justifyContent: 'center',
						p: 2,
					}}
				>
					<RouterLink to="/">
						<Box>
							<img
								alt="Logo"
								src="/static/logo.jpeg"
								style={{ maxWidth: '100%', height: 'auto' }}
							/>
						</Box>
					</RouterLink>
				</Box>
				<Divider />
				<Box sx={{ p: 2 }}>
					{sections.map((section) => (
						<NavSection
							key={section.title}
							pathname={location.pathname}
							sx={{
								'& + &': {
									mt: 3,
								},
							}}
							{...section}
						/>
					))}
				</Box>
				{openMobile && (
					<>
						<Divider />
						<Box sx={{ p: 2 }}>
							{logoutSection.map((_section, index) => (
								<NavSection
									key={index}
									pathname={location.pathname}
									sx={{
										'& + &': {
											mt: 3,
										},
									}}
									{..._section}
								/>
							))}
						</Box>
					</>
				)}
			</Scrollbar>
		</Box>
	);

	if (lgUp) {
		return (
			<Drawer
				anchor="left"
				open
				PaperProps={{
					sx: {
						backgroundColor: 'background.paper',
						height: 'calc(100% - 64px) !important',
						top: '64px !Important',
						width: 280,
					},
				}}
				variant="permanent"
			>
				{content}
			</Drawer>
		);
	}

	return (
		<Drawer
			anchor="left"
			onClose={onMobileClose}
			open={openMobile}
			PaperProps={{
				sx: {
					backgroundColor: 'background.paper',
					width: 280,
				},
			}}
			variant="temporary"
		>
			{content}
		</Drawer>
	);
};

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool,
};

export default DashboardSidebar;
