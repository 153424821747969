import { useRef, useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
	Box,
	ButtonBase,
	ListItemIcon,
	ListItemText,
	Typography,
	MenuItem,
	Popover,
} from '@material-ui/core';
import CogIcon from '../icons/Cog';
import UserIcon from '../icons/User';
//amplify
import Amplify, { Auth } from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure(config);

const AccountPopover = ({ path, menuText }) => {
	const navigate = useNavigate();
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		//deny unauthorized user access
		Auth.currentAuthenticatedUser()
			.then((user) => {
				// const group = user.signInUserSession.accessToken.payload['cognito:groups'];
				// getFullName(user.username, group[0]);
			})
			.catch((err) => {
				navigate('/login');
			});
	}, []);


	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Box
				component={ButtonBase}
				onClick={handleOpen}
				ref={anchorRef}
				sx={{
					alignItems: 'center',
					display: 'flex',
					fontSize: '1rem',
				}}
			>
				メニュー
			</Box>
			<Popover
				anchorEl={anchorRef.current}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom',
				}}
				keepMounted
				onClose={handleClose}
				open={open}
				PaperProps={{
					sx: { width: 240 },
				}}
			>
				<Box sx={{ mt: 2 }}>
					<MenuItem
						component={RouterLink}
						to={path ? path.userPath : ''}
						onClick={handleClose}
					>
						<ListItemIcon>
							<UserIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									{menuText}
								</Typography>
							}
						/>
					</MenuItem>
					<MenuItem
						component={RouterLink}
						to={path ? path.resetPasswordPath : ''}
						onClick={handleClose}
					>
						<ListItemIcon>
							<CogIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText
							primary={
								<Typography color="textPrimary" variant="subtitle2">
									パスワード再設定
								</Typography>
							}
						/>
					</MenuItem>
				</Box>
			</Popover>
		</>
	);
};

export default AccountPopover;
