import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	AppBar,
	Box,
	IconButton,
	Toolbar,
	Link,
	Divider,
	Typography,
	CircularProgress,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuIcon from '../icons/Menu';
//amplify
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify';
import { getStaffInfo } from '../graphql/queries';
import config from '../aws-exports';
Amplify.configure(config);

const AdminNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
	...(theme.palette.mode === 'light' && {
		backgroundColor: theme.palette.background.paper,
		boxShadow: 'none',
		color: theme.palette.primary.contrastText,
	}),
	...(theme.palette.mode === 'dark' && {
		backgroundColor: theme.palette.background.paper,
		borderBottom: `1px solid ${theme.palette.divider}`,
		boxShadow: 'none',
	}),
	zIndex: theme.zIndex.drawer + 100,
}));

const AdminNavbar = (props) => {
	const { onSidebarMobileOpen, ...other } = props;
	const navigate = useNavigate();
	const [fullName, setFullName] = useState(null);

	useEffect(() => {
		//deny unauthorized user access
		Auth.currentAuthenticatedUser()
			.then((user) => {
				getFullName(user.username);
			})
			.catch((err) => {
				navigate('/admin/login');
			});
	}, []);

	const getFullName = async (userName) => {
		const input = {
			id: userName,
		};
		const res_gq = await API.graphql(
			graphqlOperation(getStaffInfo, JSON.stringify(input))
		);
		console.log('res_gq...', res_gq);
		if (res_gq.data.getStaffInfo) {
			setFullName(res_gq.data.getStaffInfo.fullname);
		} else {
			throw new Error('getFullName error');
		}
	};

	return (
		<AdminNavbarRoot {...other}>
			<Toolbar sx={{ minHeight: 64 }}>
				<IconButton
					color="textSecondary"
					onClick={onSidebarMobileOpen}
					sx={{
						display: {
							lg: 'none',
						},
					}}
				>
					<MenuIcon fontSize="small" />
				</IconButton>
				<RouterLink to="/admin/dashboard">
					<Box
						sx={{
							display: {
								lg: 'block',
								xs: 'none',
							},
							width: '300px',
						}}
					>
						<img
							alt="Logo"
							src="/static/logo.jpeg"
							style={{ maxWidth: '100%', height: 'auto', paddingTop: 5 }}
						/>
					</Box>
				</RouterLink>
				<Box
					sx={{
						flexGrow: 1,
						ml: 2,
					}}
				/>
				<Box
					sx={{
						alignItems: 'center',
						display: {
							md: 'flex',
							xs: 'none',
						},
					}}
				>
					{!fullName ? (
						<CircularProgress />
					) : (
						<Typography color="textSecondary" variant="body1">
							{fullName}
						</Typography>
					)}
					<Divider
						orientation="vertical"
						sx={{
							height: 32,
							mx: 2,
						}}
					/>
					<Link
						color="textSecondary"
						component={RouterLink}
						to="/admin/login"
						underline="none"
						variant="body1"
					>
						ログアウト
					</Link>
				</Box>
			</Toolbar>
		</AdminNavbarRoot>
	);
};

AdminNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default AdminNavbar;
