/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_content_delivery_bucket": "swucareer-20210715104616-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "http://swucareer-20210715104616-hostingbucket-prod.s3-website-ap-northeast-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "ap-northeast-1:2ea93317-d4d3-4302-8456-af03b1eff028",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_wQgnQctof",
    "aws_user_pools_web_client_id": "44er8r68son0oamh4uempnf9ah",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://d2g2v4rrqvfqpbzfb3374yamie.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "swutmpcsvstorage103751-prod",
    "aws_user_files_s3_bucket_region": "ap-northeast-1",
    "aws_cognito_login_mechanisms": [
        "EMAIL",
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;
