import moment from 'moment';
export const THEMES = {
	LIGHT: 'LIGHT',
	DARK: 'DARK',
	NATURE: 'NATURE',
};

export const USER_GROUP = {
	STUDENT: 'student',
	MENTOR: 'mentor',
	STAFF: 'staff',
};

// export const INTERVIEW_STATUS = {
//   PENDING: "PENDING",
//   CANCELED: "CANCELED",
//   ESTABLISHED: "ESTABLISHED",
//   COMPLETED: "COMPLETED",
// };

export const INTERVIEW_STATUS = {
	REGISTERED: 1,
	ESTABLISHED: 2,
	COMPLETED: 3,
	CANCELED: 4,
};

export const QUESTIONNAIRE_STATUS = {
	NOT_ANSWERED: 0,
	IS_ANSWERED: 1,
	DEFAULT: 2,
};

export const holiday = [
	moment(new Date('9/5/2021')).format('YYYY/MM/DD'),
	moment(new Date('9/12/2021')).format('YYYY/MM/DD'),
	moment(new Date('9/19/2021')).format('YYYY/MM/DD'),
	moment(new Date('9/20/2021')).format('YYYY/MM/DD'),
	moment(new Date('9/23/2021')).format('YYYY/MM/DD'),
	moment(new Date('9/26/2021')).format('YYYY/MM/DD'),
	moment(new Date('10/3/2021')).format('YYYY/MM/DD'),
	moment(new Date('10/10/2021')).format('YYYY/MM/DD'),
	moment(new Date('10/17/2021')).format('YYYY/MM/DD'),
	moment(new Date('10/24/2021')).format('YYYY/MM/DD'),
	moment(new Date('10/31/2021')).format('YYYY/MM/DD'),
	moment(new Date('11/3/2021')).format('YYYY/MM/DD'),
	moment(new Date('11/7/2021')).format('YYYY/MM/DD'),
	moment(new Date('11/14/2021')).format('YYYY/MM/DD'),
	moment(new Date('11/21/2021')).format('YYYY/MM/DD'),
	moment(new Date('11/23/2021')).format('YYYY/MM/DD'),
	moment(new Date('11/28/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/5/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/12/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/19/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/26/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/27/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/28/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/29/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/30/2021')).format('YYYY/MM/DD'),
	moment(new Date('12/31/2021')).format('YYYY/MM/DD'),
	moment(new Date('1/1/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/2/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/3/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/4/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/5/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/9/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/10/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/15/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/16/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/23/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/30/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/4/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/6/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/11/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/13/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/20/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/23/2022')).format('YYYY/MM/DD'),
	moment(new Date('2/27/2022')).format('YYYY/MM/DD'),
	moment(new Date('3/5/2022')).format('YYYY/MM/DD'),
	moment(new Date('3/6/2022')).format('YYYY/MM/DD'),
	moment(new Date('3/13/2022')).format('YYYY/MM/DD'),
	moment(new Date('3/20/2022')).format('YYYY/MM/DD'),
	moment(new Date('3/21/2022')).format('YYYY/MM/DD'),
	moment(new Date('3/27/2022')).format('YYYY/MM/DD'),
	moment(new Date('4/3/2022')).format('YYYY/MM/DD'),
	moment(new Date('4/10/2022')).format('YYYY/MM/DD'),
	moment(new Date('4/17/2022')).format('YYYY/MM/DD'),
	moment(new Date('4/24/2022')).format('YYYY/MM/DD'),
	moment(new Date('4/29/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/1/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/2/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/3/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/4/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/5/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/8/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/15/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/22/2022')).format('YYYY/MM/DD'),
	moment(new Date('5/29/2022')).format('YYYY/MM/DD'),
	moment(new Date('6/5/2022')).format('YYYY/MM/DD'),
	moment(new Date('6/12/2022')).format('YYYY/MM/DD'),
	moment(new Date('6/19/2022')).format('YYYY/MM/DD'),
	moment(new Date('6/26/2022')).format('YYYY/MM/DD'),
	moment(new Date('7/3/2022')).format('YYYY/MM/DD'),
	moment(new Date('7/10/2022')).format('YYYY/MM/DD'),
	moment(new Date('7/17/2022')).format('YYYY/MM/DD'),
	moment(new Date('7/18/2022')).format('YYYY/MM/DD'),
	moment(new Date('7/24/2022')).format('YYYY/MM/DD'),
	moment(new Date('7/31/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/7/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/8/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/9/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/10/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/11/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/12/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/13/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/14/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/15/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/21/2022')).format('YYYY/MM/DD'),
	moment(new Date('8/28/2022')).format('YYYY/MM/DD'),
	moment(new Date('9/4/2022')).format('YYYY/MM/DD'),
	moment(new Date('9/11/2022')).format('YYYY/MM/DD'),
	moment(new Date('9/18/2022')).format('YYYY/MM/DD'),
	moment(new Date('9/19/2022')).format('YYYY/MM/DD'),
	moment(new Date('9/23/2022')).format('YYYY/MM/DD'),
	moment(new Date('9/25/2022')).format('YYYY/MM/DD'),
	moment(new Date('10/2/2022')).format('YYYY/MM/DD'),
	moment(new Date('10/9/2022')).format('YYYY/MM/DD'),
	moment(new Date('10/10/2022')).format('YYYY/MM/DD'),
	moment(new Date('10/16/2022')).format('YYYY/MM/DD'),
	moment(new Date('10/23/2022')).format('YYYY/MM/DD'),
	moment(new Date('10/30/2022')).format('YYYY/MM/DD'),
	moment(new Date('11/3/2022')).format('YYYY/MM/DD'),
	moment(new Date('11/6/2022')).format('YYYY/MM/DD'),
	moment(new Date('11/13/2022')).format('YYYY/MM/DD'),
	moment(new Date('11/20/2022')).format('YYYY/MM/DD'),
	moment(new Date('11/23/2022')).format('YYYY/MM/DD'),
	moment(new Date('11/27/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/4/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/11/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/18/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/25/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/26/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/27/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/28/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/29/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/30/2022')).format('YYYY/MM/DD'),
	moment(new Date('12/31/2022')).format('YYYY/MM/DD'),
	moment(new Date('1/1/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/2/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/3/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/4/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/5/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/7/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/8/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/13/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/14/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/15/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/22/2023')).format('YYYY/MM/DD'),
	moment(new Date('1/29/2023')).format('YYYY/MM/DD'),
	moment(new Date('2/4/2023')).format('YYYY/MM/DD'),
	moment(new Date('2/5/2023')).format('YYYY/MM/DD'),
	moment(new Date('2/12/2023')).format('YYYY/MM/DD'),
	moment(new Date('2/19/2023')).format('YYYY/MM/DD'),
	moment(new Date('2/23/2023')).format('YYYY/MM/DD'),
	moment(new Date('2/26/2023')).format('YYYY/MM/DD'),
	moment(new Date('3/4/2023')).format('YYYY/MM/DD'),
	moment(new Date('3/5/2023')).format('YYYY/MM/DD'),
	moment(new Date('3/12/2023')).format('YYYY/MM/DD'),
	moment(new Date('3/19/2023')).format('YYYY/MM/DD'),
	moment(new Date('3/21/2023')).format('YYYY/MM/DD'),
	moment(new Date('3/26/2023')).format('YYYY/MM/DD'),
    moment(new Date('4/2/2023')).format('YYYY/MM/DD'),
    moment(new Date('4/9/2023')).format('YYYY/MM/DD'),
    moment(new Date('4/16/2023')).format('YYYY/MM/DD'),
    moment(new Date('4/23/2023')).format('YYYY/MM/DD'),
    moment(new Date('4/29/2023')).format('YYYY/MM/DD'),
    moment(new Date('4/30/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/2/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/3/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/4/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/5/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/7/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/14/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/21/2023')).format('YYYY/MM/DD'),
    moment(new Date('5/28/2023')).format('YYYY/MM/DD'),
    moment(new Date('6/4/2023')).format('YYYY/MM/DD'),
    moment(new Date('6/11/2023')).format('YYYY/MM/DD'),
    moment(new Date('6/18/2023')).format('YYYY/MM/DD'),
    moment(new Date('6/25/2023')).format('YYYY/MM/DD'),
    moment(new Date('7/2/2023')).format('YYYY/MM/DD'),
    moment(new Date('7/9/2023')).format('YYYY/MM/DD'),
    moment(new Date('7/16/2023')).format('YYYY/MM/DD'),
    moment(new Date('7/17/2023')).format('YYYY/MM/DD'),
    moment(new Date('7/23/2023')).format('YYYY/MM/DD'),
    moment(new Date('7/30/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/5/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/6/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/7/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/8/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/9/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/10/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/11/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/12/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/13/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/14/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/19/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/20/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/26/2023')).format('YYYY/MM/DD'),
    moment(new Date('8/27/2023')).format('YYYY/MM/DD'),
    moment(new Date('9/3/2023')).format('YYYY/MM/DD'),
    moment(new Date('9/10/2023')).format('YYYY/MM/DD'),
    moment(new Date('9/17/2023')).format('YYYY/MM/DD'),
    moment(new Date('9/18/2023')).format('YYYY/MM/DD'),
    moment(new Date('9/23/2023')).format('YYYY/MM/DD'),
    moment(new Date('9/24/2023')).format('YYYY/MM/DD'),
    moment(new Date('10/1/2023')).format('YYYY/MM/DD'),
    moment(new Date('10/8/2023')).format('YYYY/MM/DD'),
    moment(new Date('10/9/2023')).format('YYYY/MM/DD'),
    moment(new Date('10/15/2023')).format('YYYY/MM/DD'),
    moment(new Date('10/22/2023')).format('YYYY/MM/DD'),
    moment(new Date('10/29/2023')).format('YYYY/MM/DD'),
    moment(new Date('11/3/2023')).format('YYYY/MM/DD'),
    moment(new Date('11/5/2023')).format('YYYY/MM/DD'),
    moment(new Date('11/12/2023')).format('YYYY/MM/DD'),
    moment(new Date('11/19/2023')).format('YYYY/MM/DD'),
    moment(new Date('11/23/2023')).format('YYYY/MM/DD'),
    moment(new Date('11/26/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/3/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/10/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/17/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/24/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/25/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/26/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/27/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/28/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/29/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/30/2023')).format('YYYY/MM/DD'),
    moment(new Date('12/31/2023')).format('YYYY/MM/DD'),
    moment(new Date('1/1/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/2/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/3/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/4/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/5/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/6/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/7/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/8/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/12/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/13/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/14/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/21/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/28/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/4/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/11/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/12/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/18/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/23/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/25/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/2/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/3/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/10/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/17/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/20/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/24/2024')).format('YYYY/MM/DD'),
    moment(new Date('3/31/2024')).format('YYYY/MM/DD'),
    moment(new Date('4/7/2024')).format('YYYY/MM/DD'),
    moment(new Date('4/14/2024')).format('YYYY/MM/DD'),
    moment(new Date('4/21/2024')).format('YYYY/MM/DD'),
    moment(new Date('4/28/2024')).format('YYYY/MM/DD'),
    moment(new Date('4/29/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/2/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/3/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/4/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/5/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/6/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/12/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/19/2024')).format('YYYY/MM/DD'),
    moment(new Date('5/26/2024')).format('YYYY/MM/DD'),
    moment(new Date('6/2/2024')).format('YYYY/MM/DD'),
    moment(new Date('6/9/2024')).format('YYYY/MM/DD'),
    moment(new Date('6/16/2024')).format('YYYY/MM/DD'),
    moment(new Date('6/23/2024')).format('YYYY/MM/DD'),
    moment(new Date('6/30/2024')).format('YYYY/MM/DD'),
    moment(new Date('7/7/2024')).format('YYYY/MM/DD'),
    moment(new Date('7/14/2024')).format('YYYY/MM/DD'),
    moment(new Date('7/15/2024')).format('YYYY/MM/DD'),
    moment(new Date('7/21/2024')).format('YYYY/MM/DD'),
    moment(new Date('7/28/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/3/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/4/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/7/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/8/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/9/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/10/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/11/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/12/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/13/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/14/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/17/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/18/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/24/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/25/2024')).format('YYYY/MM/DD'),
    moment(new Date('8/31/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/1/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/8/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/15/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/16/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/22/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/23/2024')).format('YYYY/MM/DD'),
    moment(new Date('9/29/2024')).format('YYYY/MM/DD'),
    moment(new Date('10/6/2024')).format('YYYY/MM/DD'),
    moment(new Date('10/13/2024')).format('YYYY/MM/DD'),
    moment(new Date('10/14/2024')).format('YYYY/MM/DD'),
    moment(new Date('10/20/2024')).format('YYYY/MM/DD'),
    moment(new Date('10/27/2024')).format('YYYY/MM/DD'),
    moment(new Date('11/3/2024')).format('YYYY/MM/DD'),
    moment(new Date('11/4/2024')).format('YYYY/MM/DD'),
    moment(new Date('11/10/2024')).format('YYYY/MM/DD'),
    moment(new Date('11/17/2024')).format('YYYY/MM/DD'),
    moment(new Date('11/23/2024')).format('YYYY/MM/DD'),
    moment(new Date('11/24/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/1/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/8/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/15/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/22/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/25/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/26/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/27/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/28/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/29/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/30/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/31/2024')).format('YYYY/MM/DD'),
    moment(new Date('12/29/2024')).format('YYYY/MM/DD'),
    moment(new Date('1/1/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/2/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/3/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/4/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/5/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/11/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/12/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/13/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/17/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/18/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/19/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/25/2025')).format('YYYY/MM/DD'),
    moment(new Date('1/26/2025')).format('YYYY/MM/DD'),
    moment(new Date('2/2/2025')).format('YYYY/MM/DD'),
    moment(new Date('2/3/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/4/2024')).format('YYYY/MM/DD'),
    moment(new Date('2/9/2025')).format('YYYY/MM/DD'),
    moment(new Date('2/11/2025')).format('YYYY/MM/DD'),
    moment(new Date('2/16/2025')).format('YYYY/MM/DD'),
    moment(new Date('2/23/2025')).format('YYYY/MM/DD'),
    moment(new Date('2/24/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/2/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/7/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/8/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/9/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/16/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/20/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/23/2025')).format('YYYY/MM/DD'),
    moment(new Date('3/30/2025')).format('YYYY/MM/DD'),
];

export const listTypeInterView = [
	{
		id: 1,
		label: 'Zoom',
		value: 1	
	},
	{
		id: 2,
		label: '対面',
		value: 2	
	}
]