/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getStaffInfo = /* GraphQL */ `
  query GetStaffInfo($id: ID!) {
    getStaffInfo(id: $id) {
      id
      fullname
      last_login_date
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listStaffInfos = /* GraphQL */ `
  query ListStaffInfos(
    $id: ID
    $filter: ModelStaffInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaffInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fullname
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStaffZoomAccount = /* GraphQL */ `
  query GetStaffZoomAccount($id: ID!) {
    getStaffZoomAccount(id: $id) {
      id
      account_id
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listStaffZoomAccounts = /* GraphQL */ `
  query ListStaffZoomAccounts(
    $id: ID
    $filter: ModelStaffZoomAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaffZoomAccounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        account_id
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStaffRoomAccount = /* GraphQL */ `
  query GetStaffRoomAccount($id: ID!) {
    getStaffRoomAccount(id: $id) {
      id
      room_name
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listStaffRoomAccounts = /* GraphQL */ `
  query ListStaffRoomAccounts(
    $id: ID
    $filter: ModelStaffRoomAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStaffRoomAccounts(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        room_name
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudentInfo = /* GraphQL */ `
  query GetStudentInfo($id: ID!) {
    getStudentInfo(id: $id) {
      id
      fullname
      email
      year
      department
      about_me_doc
      about_study_doc
      about_future_doc
      last_login_date
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listStudentInfos = /* GraphQL */ `
  query ListStudentInfos(
    $id: ID
    $filter: ModelStudentInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudentInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fullname
        email
        year
        department
        about_me_doc
        about_study_doc
        about_future_doc
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudentByEmail = /* GraphQL */ `
  query GetStudentByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelStudentInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getStudentByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullname
        email
        year
        department
        about_me_doc
        about_study_doc
        about_future_doc
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudentTempInitial = /* GraphQL */ `
  query GetStudentTempInitial($id: ID!) {
    getStudentTempInitial(id: $id) {
      id
      school_email
      initial_password
      createdAt
      updatedAt
    }
  }
`;
export const listStudentTempInitials = /* GraphQL */ `
  query ListStudentTempInitials(
    $id: ID
    $filter: ModelStudentTempInitialFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudentTempInitials(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        school_email
        initial_password
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStudentTempResetPassword = /* GraphQL */ `
  query GetStudentTempResetPassword($id: ID!) {
    getStudentTempResetPassword(id: $id) {
      id
      student_id
      StudentInfo {
        id
        fullname
        email
        year
        department
        about_me_doc
        about_study_doc
        about_future_doc
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      school_email
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listStudentTempResetPasswords = /* GraphQL */ `
  query ListStudentTempResetPasswords(
    $id: ID
    $filter: ModelStudentTempResetPasswordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStudentTempResetPasswords(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        school_email
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMentorInfo = /* GraphQL */ `
  query GetMentorInfo($id: ID!) {
    getMentorInfo(id: $id) {
      id
      fullname
      main_email
      sub_email
      about_me_doc
      age
      industry
      occupation
      lifeStyle
      present_past
      message_student
      interview_availability_date
      qualifications
      hobby_skill
      approval_flag
      retiring_date
      last_login_date
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listMentorInfos = /* GraphQL */ `
  query ListMentorInfos(
    $id: ID
    $filter: ModelMentorInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMentorInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        fullname
        main_email
        sub_email
        about_me_doc
        age
        industry
        occupation
        lifeStyle
        present_past
        message_student
        interview_availability_date
        qualifications
        hobby_skill
        approval_flag
        retiring_date
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMentorByMainEmail = /* GraphQL */ `
  query GetMentorByMainEmail(
    $main_email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMentorInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMentorByMainEmail(
      main_email: $main_email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fullname
        main_email
        sub_email
        about_me_doc
        age
        industry
        occupation
        lifeStyle
        present_past
        message_student
        interview_availability_date
        qualifications
        hobby_skill
        approval_flag
        retiring_date
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMentorTempResetPassword = /* GraphQL */ `
  query GetMentorTempResetPassword($id: ID!) {
    getMentorTempResetPassword(id: $id) {
      id
      mentor_id
      MentorInfo {
        id
        fullname
        email
        year
        department
        about_me_doc
        about_study_doc
        about_future_doc
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      main_email
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listMentorTempResetPasswords = /* GraphQL */ `
  query ListMentorTempResetPasswords(
    $id: ID
    $filter: ModelMentorTempResetPasswordFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMentorTempResetPasswords(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        mentor_id
        MentorInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        main_email
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterviewInfo = /* GraphQL */ `
  query GetInterviewInfo($id: ID!) {
    getInterviewInfo(id: $id) {
      id
      status
      student_id
      StudentInfo {
        id
        fullname
        email
        year
        department
        about_me_doc
        about_study_doc
        about_future_doc
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      mentor_id
      MentorInfo {
        id
        fullname
        main_email
        sub_email
        about_me_doc
        age
        industry
        occupation
        lifeStyle
        present_past
        message_student
        interview_availability_date
        qualifications
        hobby_skill
        approval_flag
        retiring_date
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      is_student_answered
      is_mentor_answered
      careerType
      last_mentor_read
      last_student_read
      last_mentor_created_chat
      last_student_created_chat
      about_me_doc
      about_study_doc
      about_future_doc
      ask_content_doc
      preferred_date_1_from
      preferred_date_2_from
      preferred_date_3_from
      preferred_date_4_from
      preferred_date_5_from
      preferred_date_1_to
      preferred_date_2_to
      preferred_date_3_to
      preferred_date_4_to
      preferred_date_5_to
      preferred_date_1_method
      preferred_date_2_method
      preferred_date_3_method
      preferred_date_4_method
      preferred_date_5_method
      student_cancel_reason_doc
      mentor_cancel_reason_doc
      staff_cancel_reason_doc
      pc_rental_flag
      zoom_usage_schedule_id
      ZoomUsageSchedule {
        id
        zoom_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      zoom_join_url
      zoom_meeting_id
      zoom_meeting_pwd
      zoom_meeting_date
      room_usage_schedule_id
      room_meeting_date
      RoomUsageSchedule {
        id
        room_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      sort
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listInterviewInfos = /* GraphQL */ `
  query ListInterviewInfos(
    $id: ID
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewInfos(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoByStatus = /* GraphQL */ `
  query QueryInterviewInfoByStatus(
    $status: Int!
    $is_student_answered: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoByStatus(
      status: $status
      is_student_answered: $is_student_answered
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoByStudentId = /* GraphQL */ `
  query QueryInterviewInfoByStudentId(
    $student_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoByStudentId(
      student_id: $student_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoByMentorId = /* GraphQL */ `
  query QueryInterviewInfoByMentorId(
    $mentor_id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoByMentorId(
      mentor_id: $mentor_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoByStudentAnswered = /* GraphQL */ `
  query QueryInterviewInfoByStudentAnswered(
    $is_student_answered: Int!
    $status: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoByStudentAnswered(
      is_student_answered: $is_student_answered
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoByMentorAnswered = /* GraphQL */ `
  query QueryInterviewInfoByMentorAnswered(
    $is_mentor_answered: Int!
    $status: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoByMentorAnswered(
      is_mentor_answered: $is_mentor_answered
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoBySort = /* GraphQL */ `
  query QueryInterviewInfoBySort(
    $sort: Int!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoBySort(
      sort: $sort
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryInterviewInfoByDeleteFlg = /* GraphQL */ `
  query QueryInterviewInfoByDeleteFlg(
    $delete_flag: Int!
    $zoom_meeting_date: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInterviewInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryInterviewInfoByDeleteFlg(
      delete_flag: $delete_flag
      zoom_meeting_date: $zoom_meeting_date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        is_student_answered
        is_mentor_answered
        careerType
        last_mentor_read
        last_student_read
        last_mentor_created_chat
        last_student_created_chat
        about_me_doc
        about_study_doc
        about_future_doc
        ask_content_doc
        preferred_date_1_from
        preferred_date_2_from
        preferred_date_3_from
        preferred_date_4_from
        preferred_date_5_from
        preferred_date_1_to
        preferred_date_2_to
        preferred_date_3_to
        preferred_date_4_to
        preferred_date_5_to
        preferred_date_1_method
        preferred_date_2_method
        preferred_date_3_method
        preferred_date_4_method
        preferred_date_5_method
        student_cancel_reason_doc
        mentor_cancel_reason_doc
        staff_cancel_reason_doc
        pc_rental_flag
        zoom_usage_schedule_id
        ZoomUsageSchedule {
          id
          zoom_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        zoom_join_url
        zoom_meeting_id
        zoom_meeting_pwd
        zoom_meeting_date
        room_usage_schedule_id
        room_meeting_date
        RoomUsageSchedule {
          id
          room_id
          dateterm
          term_id
          is_vacant
          createdAt
          updatedAt
        }
        sort
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterviewInquiryHistory = /* GraphQL */ `
  query GetInterviewInquiryHistory($id: ID!, $createdAt: AWSDateTime!) {
    getInterviewInquiryHistory(id: $id, createdAt: $createdAt) {
      id
      inquirer_type
      inquiry_contents_doc
      is_urgent
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listInterviewInquiryHistories = /* GraphQL */ `
  query ListInterviewInquiryHistories(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInterviewInquiryHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewInquiryHistories(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        inquirer_type
        inquiry_contents_doc
        is_urgent
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterviewChatHistory = /* GraphQL */ `
  query GetInterviewChatHistory($id: ID!, $createdAt: AWSDateTime!) {
    getInterviewChatHistory(id: $id, createdAt: $createdAt) {
      id
      inquirer_type
      message
      is_zoom
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listInterviewChatHistories = /* GraphQL */ `
  query ListInterviewChatHistories(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInterviewChatHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewChatHistories(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        inquirer_type
        message
        is_zoom
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterviewFeedbackHistory = /* GraphQL */ `
  query GetInterviewFeedbackHistory($id: ID!) {
    getInterviewFeedbackHistory(id: $id) {
      id
      meeting_date
      studentId
      student_question_1
      student_question_2
      student_question_3
      student_question_4
      student_question_5
      student_question_6
      student_question_7
      student_question_7_other_text
      mentorId
      mentor_question_1
      mentor_question_1_other_text
      mentor_question_2
      mentor_question_3
      mentor_question_4
      mentor_question_5
      recordCreator
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listInterviewFeedbackHistories = /* GraphQL */ `
  query ListInterviewFeedbackHistories(
    $id: ID
    $filter: ModelInterviewFeedbackHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewFeedbackHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        meeting_date
        studentId
        student_question_1
        student_question_2
        student_question_3
        student_question_4
        student_question_5
        student_question_6
        student_question_7
        student_question_7_other_text
        mentorId
        mentor_question_1
        mentor_question_1_other_text
        mentor_question_2
        mentor_question_3
        mentor_question_4
        mentor_question_5
        recordCreator
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterviewTerm = /* GraphQL */ `
  query GetInterviewTerm($id: ID!) {
    getInterviewTerm(id: $id) {
      id
      start_time
      end_time
      createdAt
      updatedAt
    }
  }
`;
export const listInterviewTerms = /* GraphQL */ `
  query ListInterviewTerms(
    $id: ID
    $filter: ModelInterviewTermFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewTerms(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        start_time
        end_time
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZoomUsageSchedule = /* GraphQL */ `
  query GetZoomUsageSchedule($id: ID!) {
    getZoomUsageSchedule(id: $id) {
      id
      zoom_id
      dateterm
      term_id
      term {
        id
        start_time
        end_time
        createdAt
        updatedAt
      }
      is_vacant
      createdAt
      updatedAt
    }
  }
`;
export const listZoomUsageSchedules = /* GraphQL */ `
  query ListZoomUsageSchedules(
    $id: ID
    $filter: ModelZoomUsageScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZoomUsageSchedules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        zoom_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryZoomUsageScheduleByZoomId = /* GraphQL */ `
  query QueryZoomUsageScheduleByZoomId(
    $zoom_id: ID!
    $dateterm: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelZoomUsageScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryZoomUsageScheduleByZoomId(
      zoom_id: $zoom_id
      dateterm: $dateterm
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        zoom_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryZoomUsageScheduleByisVacant = /* GraphQL */ `
  query QueryZoomUsageScheduleByisVacant(
    $is_vacant: Int!
    $dateterm: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelZoomUsageScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryZoomUsageScheduleByisVacant(
      is_vacant: $is_vacant
      dateterm: $dateterm
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        zoom_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRoomUsageSchedule = /* GraphQL */ `
  query GetRoomUsageSchedule($id: ID!) {
    getRoomUsageSchedule(id: $id) {
      id
      room_id
      dateterm
      term_id
      term {
        id
        start_time
        end_time
        createdAt
        updatedAt
      }
      is_vacant
      createdAt
      updatedAt
    }
  }
`;
export const listRoomUsageSchedules = /* GraphQL */ `
  query ListRoomUsageSchedules(
    $id: ID
    $filter: ModelRoomUsageScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRoomUsageSchedules(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        room_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryRoomUsageScheduleByRoomId = /* GraphQL */ `
  query QueryRoomUsageScheduleByRoomId(
    $room_id: ID!
    $dateterm: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomUsageScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryRoomUsageScheduleByRoomId(
      room_id: $room_id
      dateterm: $dateterm
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const queryRoomUsageScheduleByisVacant = /* GraphQL */ `
  query QueryRoomUsageScheduleByisVacant(
    $is_vacant: Int!
    $dateterm: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRoomUsageScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryRoomUsageScheduleByisVacant(
      is_vacant: $is_vacant
      dateterm: $dateterm
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        room_id
        dateterm
        term_id
        term {
          id
          start_time
          end_time
          createdAt
          updatedAt
        }
        is_vacant
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCountingData = /* GraphQL */ `
  query GetCountingData($id: ID!, $base_month: String!) {
    getCountingData(id: $id, base_month: $base_month) {
      id
      apply_count
      held_count
      established_count
      cancel_count
      base_month
      createdAt
      updatedAt
    }
  }
`;
export const listCountingData = /* GraphQL */ `
  query ListCountingData(
    $id: ID
    $base_month: ModelStringKeyConditionInput
    $filter: ModelCountingDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCountingData(
      id: $id
      base_month: $base_month
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        apply_count
        held_count
        established_count
        cancel_count
        base_month
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInterviewStaffMailSupport = /* GraphQL */ `
  query GetInterviewStaffMailSupport($id: ID!) {
    getInterviewStaffMailSupport(id: $id) {
      id
      staff_id
      StaffInfo {
        id
        fullname
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      student_id
      StudentInfo {
        id
        fullname
        email
        year
        department
        about_me_doc
        about_study_doc
        about_future_doc
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      mentor_id
      MentorInfo {
        id
        fullname
        main_email
        sub_email
        about_me_doc
        age
        industry
        occupation
        lifeStyle
        present_past
        message_student
        interview_availability_date
        qualifications
        hobby_skill
        approval_flag
        retiring_date
        last_login_date
        delete_flag
        createdAt
        updatedAt
      }
      interview_id
      mail_to
      role_mail_to
      mail_title
      mail_body
      staff_memo
      delete_flag
      createdAt
      updatedAt
    }
  }
`;
export const listInterviewStaffMailSupports = /* GraphQL */ `
  query ListInterviewStaffMailSupports(
    $id: ID
    $filter: ModelInterviewStaffMailSupportFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInterviewStaffMailSupports(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        staff_id
        StaffInfo {
          id
          fullname
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        student_id
        StudentInfo {
          id
          fullname
          email
          year
          department
          about_me_doc
          about_study_doc
          about_future_doc
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        mentor_id
        MentorInfo {
          id
          fullname
          main_email
          sub_email
          about_me_doc
          age
          industry
          occupation
          lifeStyle
          present_past
          message_student
          interview_availability_date
          qualifications
          hobby_skill
          approval_flag
          retiring_date
          last_login_date
          delete_flag
          createdAt
          updatedAt
        }
        interview_id
        mail_to
        role_mail_to
        mail_title
        mail_body
        staff_memo
        delete_flag
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const publishTempPassword = /* GraphQL */ `
  query PublishTempPassword($input: publishTempPasswordInput!) {
    publishTempPassword(input: $input)
  }
`;
export const publishPasswordResetLink = /* GraphQL */ `
  query PublishPasswordResetLink($input: publishPasswordResetLinkInput!) {
    publishPasswordResetLink(input: $input)
  }
`;
export const publishPasswordResetLinkMentor = /* GraphQL */ `
  query PublishPasswordResetLinkMentor($input: publishPasswordResetLinkInput!) {
    publishPasswordResetLinkMentor(input: $input)
  }
`;
export const settingCognitoPassword = /* GraphQL */ `
  query SettingCognitoPassword($input: settingCognitoPasswordInput!) {
    settingCognitoPassword(input: $input)
  }
`;
export const checkLinkValidation = /* GraphQL */ `
  query CheckLinkValidation($input: checkLinkValidationInput!) {
    checkLinkValidation(input: $input)
  }
`;
export const checkLinkValidationMentor = /* GraphQL */ `
  query CheckLinkValidationMentor($input: checkLinkValidationInput!) {
    checkLinkValidationMentor(input: $input)
  }
`;
export const mentorCsvRegistration = /* GraphQL */ `
  query MentorCsvRegistration($s3Bucket: String!, $s3Key: String!) {
    mentorCsvRegistration(s3Bucket: $s3Bucket, s3Key: $s3Key)
  }
`;
export const sendMailInquiry = /* GraphQL */ `
  query SendMailInquiry(
    $id: ID!
    $studentId: ID!
    $mentorId: ID!
    $inquirer_type: String!
    $inquiry: String!
    $isUrgent: Boolean!
  ) {
    sendMailInquiry(
      id: $id
      studentId: $studentId
      mentorId: $mentorId
      inquirer_type: $inquirer_type
      inquiry: $inquiry
      isUrgent: $isUrgent
    )
  }
`;
export const sendMailCancel = /* GraphQL */ `
  query SendMailCancel($id: ID!, $sentBy: String!, $reasonCancel: String!) {
    sendMailCancel(id: $id, sentBy: $sentBy, reasonCancel: $reasonCancel)
  }
`;
export const sendMailQuestionnaire = /* GraphQL */ `
  query SendMailQuestionnaire($input: AWSJSON!) {
    sendMailQuestionnaire(input: $input)
  }
`;
export const sendMailResetInterview = /* GraphQL */ `
  query SendMailResetInterview($id: ID!, $preferredMethod: String) {
    sendMailResetInterview(id: $id, preferredMethod: $preferredMethod)
  }
`;
export const changeCognitoMentorEmail = /* GraphQL */ `
  query ChangeCognitoMentorEmail($mentorId: ID!, $newEmail: String!) {
    changeCognitoMentorEmail(mentorId: $mentorId, newEmail: $newEmail)
  }
`;
export const sendMailSupport = /* GraphQL */ `
  query SendMailSupport(
    $interviewId: String!
    $sendTo: [String]!
    $titleMail: String!
    $contentMail: String!
  ) {
    sendMailSupport(
      interviewId: $interviewId
      sendTo: $sendTo
      titleMail: $titleMail
      contentMail: $contentMail
    )
  }
`;
