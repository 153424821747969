import { Suspense, lazy } from "react";
import LoadingScreen from "./components/LoadingScreen";
import MainLayout from "./components/MainLayout";
import DashboardLayout from "./components/DashboardLayout";
import AppLayout from "./components/AppLayout";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

// Error pages

const NotFound = Loadable(lazy(() => import("./pages/NotFound")));

// ------- SWU-career starts here -------
// Top page
const Top = Loadable(lazy(() => import("./pages/Top")));

// Student pages
const StudentLogin = Loadable(lazy(() => import("./pages/student/Login")));
const StudentRegister = Loadable(
  lazy(() => import("./pages/student/Register"))
);
const StudentPasswordReset = Loadable(
  lazy(() => import("./pages/student/PasswordReset"))
);
const StudentPasswordSettings = Loadable(
  lazy(() => import("./pages/student/PasswordSettings"))
);
const StudentPasswordSettingsLoggedIn = Loadable(
  lazy(() => import("./pages/student/PasswordSettingsLoggedIn"))
);
const StudentProfileRegister = Loadable(
  lazy(() => import("./pages/student/ProfileRegister"))
);
const StudentProfileEdit = Loadable(
  lazy(() => import("./pages/student/ProfileEdit"))
);
// A08
const StudentInterviewList = Loadable(
  lazy(() => import("./pages/student/InterviewList"))
);
// A09
const StudentApply = Loadable(lazy(() => import("./pages/student/Apply")));
// A?
const StudentInterviewInfo = Loadable(
  lazy(() => import("./pages/student/InterviewInfo"))
);
// A17
const StudentMentorList = Loadable(
  lazy(() => import("./pages/student/MentorList"))
);
// A18
const StudentMentorInfo = Loadable(
  lazy(() => import("./pages/student/MentorInfo"))
);

// Mentor pages
const MentorLogin = Loadable(lazy(() => import("./pages/mentor/MentorLogin")));
const MentorPasswordReset = Loadable(
  lazy(() => import("./pages/mentor/MentorPasswordReset"))
);
const MentorPasswordSettings = Loadable(
  lazy(() => import("./pages/mentor/MentorPasswordSettings"))
);
const MentorPasswordSettingsLoggedIn = Loadable(
  lazy(() => import("./pages/mentor/MentorPasswordSettingsLoggedIn"))
);
const MentorProfile = Loadable(
  lazy(() => import("./pages/mentor/MentorProfile"))
);
const MentorProfileEdit = Loadable(
  lazy(() => import("./pages/mentor/MentorProfileEdit"))
);
// B07
const Chat = Loadable(lazy(() => import("./pages/Chat")));
// B05
const MentorInterviewList = Loadable(
  lazy(() => import("./pages/mentor/InterviewList"))
);
// B06
const MentorInterviewInfo = Loadable(
  lazy(() => import("./pages/mentor/InterviewInfo"))
);

// Admin pages
const AdminLogin = Loadable(lazy(() => import("./pages/admin/AdminLogin")));
const AdminSeeStudentProfile = Loadable(
  lazy(() => import("./pages/admin/StudentProfile"))
);
// C02
const Dashboard = Loadable(lazy(() => import("./pages/admin/Dashboard")));
// C05
const MentorList = Loadable(lazy(() => import("./pages/admin/MentorList")));
// C03
const StudentList = Loadable(lazy(() => import("./pages/admin/StudentList")));
// C08
const InterviewList = Loadable(
  lazy(() => import("./pages/admin/InterviewList"))
);
// C09
const InterviewInfo = Loadable(
  lazy(() => import("./pages/admin/InterviewInfo"))
);
// C14
const MailSupportList = Loadable(
  lazy(() => import("./pages/admin/MailSupportList"))
);
//C15
const MailSupportDetail = Loadable(
  lazy(() => import("./pages/admin/MailSupportDetail"))
);
// C16
const MailSend = Loadable(lazy(() => import("./pages/admin/MailSend")));
// C16
const UnApprovedList = Loadable(lazy(() => import("./pages/admin/UnApprovedList")));
const routes = [
  // SWU-career starts here
  {
    path: "admin",
    children: [
      // pageID C01
      {
        path: "login",
        element: (
          <MainLayout>
            <AdminLogin />
          </MainLayout>
        ),
      },
      // pageID C04
      {
        path: "student/:id",
        element: (
          <DashboardLayout>
            <AdminSeeStudentProfile />
          </DashboardLayout>
        ),
      },
      // pageID C06, C07
      {
        path: "mentor/:id",
        element: (
          <DashboardLayout>
            <MentorProfileEdit />
          </DashboardLayout>
        ),
      },
      // pageID C02
      {
        path: "dashboard",
        element: (
          <DashboardLayout>
            <Dashboard />
          </DashboardLayout>
        ),
      },
      // pageID C05
      {
        path: "mentor/list",
        element: (
          <DashboardLayout>
            <MentorList />
          </DashboardLayout>
        ),
      },
      // pageID C03
      {
        path: "student/list",
        element: (
          <DashboardLayout>
            <StudentList />
          </DashboardLayout>
        ),
      },
      // pageID C08
      {
        path: "meeting/list",
        element: (
          <DashboardLayout>
            <InterviewList />
          </DashboardLayout>
        ),
      },
      // pageID C09, C10, C11, C12, C13
      {
        path: "meeting/:id",
        element: (
          <DashboardLayout>
            <InterviewInfo />
          </DashboardLayout>
        ),
      },
      // pageID C14
      {
        path: "mailsupport/list",
        element: (
          <DashboardLayout>
            <MailSupportList />
          </DashboardLayout>
        ),
      },
      // pageID C15
      {
        path: "mailsupport/mailsend",
        element: (
          <DashboardLayout>
            <MailSend />
          </DashboardLayout>
        ),
      },
      // pageID C16
      {
        path: "mailsupport/:id",
        element: (
          <DashboardLayout>
            <MailSupportDetail />
          </DashboardLayout>
        ),
      },
      // pageID C17
      {
        path: "unapproved/list",
        element: (
          <DashboardLayout>
            <UnApprovedList />
          </DashboardLayout>
        ),
      },
    ],
  },
  {
    path: "mentor",
    children: [
      // pageID B01
      {
        path: "login",
        element: (
          <MainLayout>
            <MentorLogin />
          </MainLayout>
        ),
      },
      // pageID B02
      {
        path: "passwordreset",
        element: (
          <MainLayout>
            <MentorPasswordReset />
          </MainLayout>
        ),
      },
      // pageID B03
      {
        path: "passwordsetting",
        element: (
          <MainLayout>
            <MentorPasswordSettings />
          </MainLayout>
        ),
      },
      // pageID ??
      {
        path: "passwordsetting/:id",
        element: (
          <MainLayout>
            <MentorPasswordSettings />
          </MainLayout>
        ),
      },
      // pageID ??
      {
        path: "passwordsetting/logged",
        element: (
          <AppLayout>
            <MentorPasswordSettingsLoggedIn />
          </AppLayout>
        ),
      },
      // pageID B04
      {
        path: "profile",
        element: (
          <AppLayout>
            <MentorProfile />
          </AppLayout>
        ),
      },
      // pageID B05
      {
        path: "meeting/list",
        element: (
          <AppLayout>
            <MentorInterviewList />
          </AppLayout>
        ),
      },
      // pageID B06, B07, B08, B09, B10, B11, B12
      {
        path: "meeting/:id",
        element: (
          <AppLayout>
            <MentorInterviewInfo />
          </AppLayout>
        ),
      },
    ],
  },
  {
    path: "*",
    children: [
      // pageID A01
      {
        path: "/",
        element: (
          <MainLayout>
            <Top />
          </MainLayout>
        ),
      },
      // pageID A04
      {
        path: "login",
        element: (
          <MainLayout>
            <StudentLogin />
          </MainLayout>
        ),
      },
      // pageID A02
      {
        path: "regist",
        element: (
          <MainLayout>
            <StudentRegister />
          </MainLayout>
        ),
      },
      // pageID A03
      {
        path: "passwordreset",
        element: (
          <MainLayout>
            <StudentPasswordReset />
          </MainLayout>
        ),
      },
      // pageID A05
      {
        path: "passwordsetting",
        element: (
          <MainLayout>
            <StudentPasswordSettings />
          </MainLayout>
        ),
      },
      // pageID A05
      {
        path: "passwordsetting/:id",
        element: (
          <MainLayout>
            <StudentPasswordSettings />
          </MainLayout>
        ),
      },
      // pageID ??
      {
        path: "passwordsetting/logged",
        element: (
          <AppLayout>
            <StudentPasswordSettingsLoggedIn />
          </AppLayout>
        ),
      },
      // pageID A06
      {
        path: "profile",
        element: (
          <MainLayout>
            <StudentProfileRegister />
          </MainLayout>
        ),
      },
      // pageID A07
      {
        path: "profile/edit",
        element: (
          <AppLayout>
            <StudentProfileEdit />
          </AppLayout>
        ),
      },
      // pageID ??
      {
        path: "meeting/:id/chat",
        element: (
          <AppLayout>
            <Chat />
          </AppLayout>
        ),
      },
      // pageID A08
      {
        path: "meeting/list",
        element: (
          <AppLayout>
            <StudentInterviewList />
          </AppLayout>
        ),
      },
      // pageID A09
      {
        path: "meeting/apply",
        element: (
          <AppLayout>
            <StudentApply />
          </AppLayout>
        ),
      },
      // pageID A10, A11, A12, A13, A14, A15, A16
      {
        path: "meeting/:id",
        element: (
          <AppLayout>
            <StudentInterviewInfo />
          </AppLayout>
        ),
      },
      // pageID A17
      {
        path: "mentorList",
        element: (
          <AppLayout>
            <StudentMentorList />
          </AppLayout>
        ),
      },
      // pageID A18
      {
        path: "mentorList/:id",
        element: (
          <AppLayout>
            <StudentMentorInfo />
          </AppLayout>
        ),
      },
      {
        path: "*",
        element: (
          <MainLayout>
            <NotFound />
          </MainLayout>
        ),
      },
    ],
  },
];

export default routes;
